import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig'; // Adjust the path as needed
import { collection, getDocs, query, orderBy } from 'firebase/firestore';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, 'Users');
        const q = query(usersCollection, orderBy('createdAt', 'desc'));
        const usersSnapshot = await getDocs(q);
        const usersList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUsers(usersList);
        setFilteredUsers(usersList); // Initialize the filtered list
      } catch (error) {
        console.error("Error fetching users: ", error);
      }
    };

    fetchUsers();
  }, []);

  // Handle search
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = users.filter(
      (user) =>
        (user.name && user.name.toLowerCase().includes(value)) ||
        (user.email && user.email.toLowerCase().includes(value))
    );
    setFilteredUsers(filtered);
    setPage(0); // Reset to first page when searching
  };

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when changing rows per page
  };

  return (
    <TableContainer component={Paper}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px' }}>
        {/* Bouton retour */}
        <Button variant="outlined" color="primary" onClick={() => navigate(-1)}>
          Retour
        </Button>

        {/* Champ de recherche */}
        <TextField
          label="Rechercher par Nom ou Email"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearch}
          style={{ flexGrow: 1, margin: '0 10px' }}
        />

        {/* Bouton Ajouter un utilisateur */}
        <Button variant="contained" color="primary">
          Ajouter un utilisateur
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nom</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Statut</TableCell>
            <TableCell>Date d'inscription</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(user => (
            <TableRow key={user.id}>
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.status}</TableCell>
              <TableCell>
                {user.createdAt ? new Date(user.createdAt.seconds * 1000).toLocaleDateString() : 'N/A'}
              </TableCell>
              <TableCell>
                <Button variant="outlined" color="primary" style={{ marginRight: '10px' }}>
                  Modifier
                </Button>
                <Button variant="outlined" color="secondary">
                  Supprimer
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={filteredUsers.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]} // Options for number of rows per page
        labelRowsPerPage="Lignes par page"
      />
    </TableContainer>
  );
};

export default UserManagement;
