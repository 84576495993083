import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Grid,
  Box,
  CircularProgress,
  Button,
} from "@mui/material";
import { styled } from '@mui/system';
import { useNavigate } from "react-router-dom"; // Import pour la navigation

// Stylisation des cartes d'abonnement
const SubscriptionCard = styled(Card)({
  backgroundColor: "#f5f5f5",
  margin: "20px",
  borderRadius: "10px",
  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
});

const Abonnementss = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [summary, setSummary] = useState({});
  const [loadingSummary, setLoadingSummary] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const navigate = useNavigate(); // Hook pour la navigation

  useEffect(() => {
    const loadSubscriptions = async () => {
      try {
        const usersCollection = collection(db, "Users");
        const userSnapshot = await getDocs(usersCollection);
        const allSubscriptions = [];

        for (const userDoc of userSnapshot.docs) {
          const userData = userDoc.data();
          const subscriptionsCollection = collection(userDoc.ref, "subscriptions");
          const subscriptionsSnapshot = await getDocs(subscriptionsCollection);

          const userSubscriptions = subscriptionsSnapshot.docs.map((subscriptionDoc) => {
            const subscriptionData = subscriptionDoc.data();

            // Extraire uniquement les seconds
            const startDateSeconds = subscriptionData.current_period_start?.seconds || 0;
            const endDateSeconds = subscriptionData.current_period_end?.seconds || 0;

            // Convertir les timestamps en format jj/MM/aaaa
            const formatDate = (timestamp) => {
              const date = new Date(timestamp * 1000); // Convertir seconds en millisecondes
              return date.toLocaleDateString("fr-FR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              });
            };

            return {
              email: userData.email,
              subscriberName: userData.name || "Nom inconnu", // Ajouter le nom de l'abonné
              name: subscriptionData.items[0]?.price.product.name || "Produit inconnu",
              status: subscriptionData.status,
              startDate: formatDate(startDateSeconds),
              endDate: formatDate(endDateSeconds),
              startDateTimestamp: startDateSeconds,
              endDateTimestamp: endDateSeconds,
            };
          });

          const activeSubscriptions = userSubscriptions.filter(sub => sub.status === "active");
          allSubscriptions.push(...activeSubscriptions);
        }

        setSubscriptions(allSubscriptions);

        const summaryData = allSubscriptions.reduce((acc, subscription) => {
          const { name } = subscription;
          if (!acc[name]) {
            acc[name] = 1;
          } else {
            acc[name]++;
          }
          return acc;
        }, {});
        setSummary(summaryData);

        setLoadingSummary(false);
        setLoadingTable(false);
      } catch (err) {
        console.error("Erreur lors du chargement des abonnements :", err);
        setLoadingSummary(false);
        setLoadingTable(false);
      }
    };

    loadSubscriptions();
  }, []);

  const handleSort = (key) => {
    const isAscending = sortConfig.key === key && sortConfig.direction === "asc";
    setSortConfig({ key, direction: isAscending ? "desc" : "asc" });
    const sortedData = [...subscriptions].sort((a, b) => {
      if (isAscending) {
        return a[key] < b[key] ? 1 : -1;
      } else {
        return a[key] > b[key] ? 1 : -1;
      }
    });
    setSubscriptions(sortedData);
  };

  return (
    <Box sx={{ padding: "20px", backgroundColor: "#f0f0f0" }}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => navigate(-1)} // Naviguer à la page précédente
        sx={{ marginBottom: "20px" }}
      >
        Retour
      </Button>

      {/* Section des cartes résumant les abonnements */}
      <Grid container spacing={3} justifyContent="center">
        {loadingSummary ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
            <CircularProgress />
          </Box>
        ) : (
          Object.keys(summary).map((plan) => (
            <Grid item xs={12} sm={6} md={4} key={plan}>
              <SubscriptionCard>
                <CardContent>
                  <Typography variant="h6" color="primary" gutterBottom>
                    {plan}
                  </Typography>
                  <Typography variant="h4" color="textSecondary">
                    {summary[plan]} 
                  </Typography>
                </CardContent>
              </SubscriptionCard>
            </Grid>
          ))
        )}
      </Grid>

      {/* Tableau des abonnements */}
      {loadingTable ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ marginTop: "20px", borderRadius: "10px", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#3f51b5" }}>
                <TableCell sx={{ color: "#fff" }}>Nom de l'abonné</TableCell>
                <TableCell sx={{ color: "#fff" }}>Email</TableCell>
                <TableCell sx={{ color: "#fff" }}>Plan</TableCell>
                <TableCell sx={{ color: "#fff" }}>Status</TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <TableSortLabel
                    active={sortConfig.key === "startDateTimestamp"}
                    direction={sortConfig.key === "startDateTimestamp" ? sortConfig.direction : "asc"}
                    onClick={() => handleSort("startDateTimestamp")}
                  >
                    Date de début
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <TableSortLabel
                    active={sortConfig.key === "endDateTimestamp"}
                    direction={sortConfig.key === "endDateTimestamp" ? sortConfig.direction : "asc"}
                    onClick={() => handleSort("endDateTimestamp")}
                  >
                    Date de fin
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions.map((subscription, index) => (
                <TableRow key={index} sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" } }}>
                  <TableCell>{subscription.subscriberName}</TableCell>
                  <TableCell>{subscription.email}</TableCell>
                  <TableCell>{subscription.name}</TableCell>
                  <TableCell>{subscription.status}</TableCell>
                  <TableCell>{subscription.startDate}</TableCell>
                  <TableCell>{subscription.endDate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default Abonnementss;
