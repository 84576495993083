import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Avatar,
  CircularProgress,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { NavLink } from "react-router-dom";
import PeopleIcon from "@mui/icons-material/People";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import SettingsIcon from "@mui/icons-material/Settings";
import BarChartIcon from "@mui/icons-material/BarChart";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";

const DashboardAdmin = () => {
  const [activeUsersCount, setActiveUsersCount] = useState(0);
  const [newSubscriptionsCount, setNewSubscriptionsCount] = useState(0);
  const [revenue, setRevenue] = useState("0.00");
  const [salesStats, setSalesStats] = useState([]);
  const [loadingStats, setLoadingStats] = useState(true);

  // Chargement pour les cartes
  const [loadingActiveUsers, setLoadingActiveUsers] = useState(true);
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(true);
  const [loadingRevenue, setLoadingRevenue] = useState(true);

  const fetchCounts = async () => {
    try {
      // Récupération des utilisateurs
      const usersCollection = collection(db, "Users");
      const userSnapshot = await getDocs(usersCollection);

      // Transformation des documents en liste d'utilisateurs
      const userList = userSnapshot.docs.map((doc) => doc.data());

      // Compter uniquement les utilisateurs avec status === "Active"
      const activeUsers = userList.filter((user) => user.status === "Active").length;
      setActiveUsersCount(activeUsers);
      setLoadingActiveUsers(false);

      // Récupération des abonnements actifs
      let activeSubscriptionsCount = 0;
      const monthlySubscriptions = {};

      for (const userDoc of userSnapshot.docs) {
        const subscriptionsCollection = collection(userDoc.ref, "subscriptions");
        const subscriptionsSnapshot = await getDocs(subscriptionsCollection);

        subscriptionsSnapshot.docs.forEach((subscriptionDoc) => {
          const subscriptionData = subscriptionDoc.data();
          if (subscriptionData.status === "active") {
            activeSubscriptionsCount++;

            // Extraction de la date de début pour regrouper par mois
            const startDate = new Date(
              subscriptionData.current_period_start.seconds * 1000
            );
            const month = startDate.toLocaleString("default", { month: "short" });

            monthlySubscriptions[month] = (monthlySubscriptions[month] || 0) + 1;
          }
        });
      }

      setNewSubscriptionsCount(activeSubscriptionsCount);
      setLoadingSubscriptions(false);

      // Transformation des données pour le graphique
      const statsData = Object.keys(monthlySubscriptions).map((month) => ({
        name: month,
        subscriptions: monthlySubscriptions[month],
        users: activeUsers, // Inclure uniquement les utilisateurs actifs
      }));
      setSalesStats(statsData);
      setLoadingStats(false);

      // Récupération des revenus
      let totalRevenue = 0;
      for (const userDoc of userSnapshot.docs) {
        const subscriptionsCollection = collection(userDoc.ref, "subscriptions");
        const subscriptionsSnapshot = await getDocs(subscriptionsCollection);

        subscriptionsSnapshot.docs.forEach((subscriptionDoc) => {
          const subscriptionData = subscriptionDoc.data();
          if (subscriptionData.status === "active") {
            subscriptionData.items.forEach((item) => {
              totalRevenue += item.plan.amount; // Montant en centimes
            });
          }
        });
      }
      setRevenue((totalRevenue / 100).toFixed(2));
      setLoadingRevenue(false);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
      setLoadingActiveUsers(false);
      setLoadingSubscriptions(false);
      setLoadingRevenue(false);
      setLoadingStats(false);
    }
  };

  useEffect(() => {
    fetchCounts();

    const intervalId = setInterval(fetchCounts, 60000); // Actualisation toutes les 60 secondes
    return () => clearInterval(intervalId); // Nettoyage de l'intervalle lors du démontage
  }, []);

  return (
    <Box sx={{ p: 3, backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
      <Typography variant="h4" gutterBottom>
        Tableau de Bord
      </Typography>
      <Grid container spacing={3}>
        {/* Carte Utilisateurs actifs */}
        <Grid item xs={12} md={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
              <Avatar sx={{ bgcolor: "#1976d2", mb: 2 }}>
                <PeopleIcon />
              </Avatar>
              <Typography variant="h6">Utilisateurs actifs</Typography>
              {loadingActiveUsers ? (
                <CircularProgress />
              ) : (
                <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                  {activeUsersCount}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Carte Nouveaux abonnements */}
        <Grid item xs={12} md={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
              <Avatar sx={{ bgcolor: "#ff9800", mb: 2 }}>
                <PersonAddIcon />
              </Avatar>
              <Typography variant="h6">Abonnements actifs</Typography>
              {loadingSubscriptions ? (
                <CircularProgress />
              ) : (
                <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                  {newSubscriptionsCount}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Carte Revenu */}
        <Grid item xs={12} md={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
              <Avatar sx={{ bgcolor: "#4caf50", mb: 2 }}>
                <MonetizationOnIcon />
              </Avatar>
              <Typography variant="h6">Revenu</Typography>
              {loadingRevenue ? (
                <CircularProgress />
              ) : (
                <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                  €{revenue}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Statistiques des ventes */}
        <Grid item xs={12}>
          <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6">Statistiques des ventes</Typography>
              {loadingStats ? (
                <CircularProgress />
              ) : (
                <LineChart width={800} height={400} data={salesStats}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="subscriptions" stroke="#8884d8" />
                  <Line type="monotone" dataKey="users" stroke="#82ca9d" />
                </LineChart>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Boutons */}
      <Box sx={{ mt: 4, display: "flex", justifyContent: "space-around" }}>
        <NavLink to="/UserManagement" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<PeopleIcon />}
            sx={{ minWidth: 150 }}
          >
            Utilisateurs
          </Button>
        </NavLink>
        <NavLink to="/Abonnementss" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SubscriptionsIcon />}
            sx={{ minWidth: 150 }}
          >
            Abonnements
          </Button>
        </NavLink>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SettingsIcon />}
          sx={{ minWidth: 150 }}
        >
          Paramètres
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<BarChartIcon />}
          sx={{ minWidth: 150 }}
        >
          Rapports
        </Button>
      </Box>
    </Box>
  );
};

export default DashboardAdmin;
